import Chartist from 'chartist';

/*
  This plugins adds a way to display only some of the points.
  The parameters have filters to do this task.
  This was necessary to display only the 1st and last points.
*/

(() /* istanbul ignore next: chartist code */ => {
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.wbOnlyPoints = (dataFilter, pointFilter) => (chart) => {
    chart.on('draw', (data) => {
      if (dataFilter(data)) {
        const points = data.path.pathElements.filter(pathElement => pointFilter(data.path.pathElements, pathElement));
        points.forEach((pathElement) => {
          const serie = chart.svg.querySelector(`.ct-series:nth-child(${data.index + 1})`);
          const point = serie.elem('line',
            {
              x1: pathElement.x,
              y1: pathElement.y,
              x2: pathElement.x + 0.01,
              y2: pathElement.y,
            },
            'ct-point');

          chart.eventEmitter.emit('draw', {
            type: 'point',
            value: pathElement.data.value,
            index: pathElement.data.valueIndex,
            meta: pathElement.data.meta,
            group: serie,
            element: point,
            x: pathElement.x,
            y: pathElement.y,
          });
        });
      }
    });
  };
})();
