





























































































































































































































import { defineComponent, reactive, onMounted, watch } from "@vue/composition-api";
import {
  provinceList,
  provinceListFr,
  getMarginalTaxRate,
  getTotalTaxes,
  showPercent,
  showCurrency,
  incomeRules,
  depositRules,
  percentRules,
  yearRules
} from '../../lib/calc_lib'
import { $t, eventBus } from '../../main'
import Locale from '../../utils/locale';

export default defineComponent({
  setup () {
    const [provList, provListFr] = [provinceList, provinceListFr];

    const state = reactive({
      language: Locale.getLanguage(),
      currentIncome: 100000,
      retireIncome: 80000,
      province: 'BC',
      deposit: 1000,
      roi: 6.50,
      inflation: 2.50,
      yearsInvested: 30,
    });

    const results = reactive({
      marginalRate: 0.325,
      averageRate: 0.205,
      realRate: 1.25,
      rrspDeposit: 0,
      tfsaDeposit: 0,
      bothDeposit: 0,
      rrspValue: 0,
      tfsaValue: 0,
      bothValue: 0,
      rrspTax: 0,
      tfsaTax: 0,
      bothTax: 0,
    });

    const calculateRates = () => {
      results.realRate = (1 + (state.roi/100)) / (1 + (state.inflation/100));
      results.marginalRate = getMarginalTaxRate(state.currentIncome, state.province);
      results.averageRate = getTotalTaxes(state.retireIncome, state.province) / state.retireIncome;

      results.tfsaDeposit = state.deposit;
      results.rrspDeposit = state.deposit * (1 + (results.marginalRate / (1 - results.marginalRate)));
      results.bothDeposit = (1 + results.marginalRate) * state.deposit;

      results.tfsaValue = results.tfsaDeposit * (results.realRate ** state.yearsInvested);
      results.rrspValue = results.rrspDeposit * (results.realRate ** state.yearsInvested);
      results.bothValue = results.bothDeposit * (results.realRate ** state.yearsInvested);

      results.rrspTax = -results.rrspValue * results.averageRate;
      results.bothTax = -results.tfsaValue * results.averageRate;
    };

    onMounted(() => {
      calculateRates();
      eventBus.$on('language-change', (language: string) => { state.language = language });
    });

    watch(state, () => {
      calculateRates();
    });

    return {
      provList,
      provListFr,
      state,
      results,
      showPercent,
      showCurrency,
      calculateRates,
      incomeRules,
      depositRules,
      percentRules,
      yearRules,
      $t,
    };
  },
});
