import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import faSvgIconValues from 'vuetify/src/services/icons/presets/fa-svg';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(fas, far, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuetify);

Object.assign(
  faSvgIconValues,
  {
    'dollar-sign': {
      component: "font-awesome-icon",
      props: { icon: ["fas", "dollar-sign"] },
    },

    percent: {
      component: "font-awesome-icon",
      props: { icon: ["fas", "percent"] },
    },
  }
)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: faSvgIconValues,
  },
  theme: {
    themes: {
      light: {
        primary: '#00b298',
        accent: '#0e6b64',
        secondary: '#787a97',

        error: '#b31a1a',
        info: '#0a884e',
        success: '#0a884e',
        warning: '#da9f18',

        bg50: '#ebf4f1',
        bg100: '#d2e6de',

        neutral: '#d0d0d9'
      },
    },
    options: {
      customProperties: true
    },
  },
});

export default vuetify;
