






























































































import { defineComponent, reactive, onMounted } from "@vue/composition-api";
import { eventBus } from '../../src/main';
import locale from '../utils/locale';

export default defineComponent({
  setup() {
    const state = reactive({
      language: locale.getLanguage(),
    });

    onMounted(() => {
      eventBus.$on('language-change', (language: string) => state.language = language);
    });

    return { state };
  },
});
