import Locale from '../utils/locale';

export function getLocaleBuilder(getLanguage: () => string) {
  return () => {
    if (getLanguage() === 'french') {
      return 'fr-CA';
    }
    return 'en-CA';
  };
}
export const getLocale = getLocaleBuilder(Locale.getLanguage);
