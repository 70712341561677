import Storage from './storage';
import { changeLanguage } from '@wealthbar/i18n';
import { eventBus } from '../main';

let currentLanguage: string | null = null;

export function getLocale() {
  const language = document.documentElement.lang || window.navigator.language;
  return language.slice(0, 2) === 'fr' ? 'fr-CA' : 'en-US';
}

export function getLanguage() {
  if (currentLanguage) {
    return currentLanguage;
  }
  const match = window.location.search.match(/\blang=([^&#]*)\b/);
  const langUrl = !match ? null : match[1].toLowerCase();
  const langStorage = Storage.getItem('language');
  if (langUrl === 'fr' || langUrl === 'fr-ca') {
    currentLanguage = 'french';
  } else if (langUrl === 'en') {
    currentLanguage = 'english';
  } else if (langStorage) {
    currentLanguage = langStorage;
  } else {
    currentLanguage = getLocale() === 'fr-CA' ? 'french' : 'english';
  }
  Storage.setItem('language', currentLanguage);
  return currentLanguage;
}

export function setLanguage(language: string) {
  const hasLang = window.location.search.match(/\blang=/);

  if (language === 'french') {
    Storage.setItem('language', 'french');
    currentLanguage = 'french';
    changeLanguage('fr-CA');

    if (hasLang) {
      window.location.search = window.location.search.replace(/lang=en/, 'lang=fr-ca');
    }
  } else {
    Storage.setItem('language', 'english');
    currentLanguage = 'english';
    changeLanguage('en');

    if (hasLang) {
      window.location.search = window.location.search.replace(/lang=fr(?:-ca)?/, 'lang=en');
    }
  }

  setTimeout(() => {
    eventBus.$emit('language-change', language);        
  }, 100)
}

export function isFrench() {
  return (getLanguage() === 'french');
}

export default {
  getLocale,
  getLanguage,
  setLanguage,
  isFrench,
};
