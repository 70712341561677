<template>
  <div>
    <v-btn-toggle
      rounded
      active-class="black white--text"
      mandatory
      v-model="toggleLanguage"
    >
      <v-btn small v-on:click="setLanguage('english')">English</v-btn>
      <v-btn small v-on:click="setLanguage('french')">Français</v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import Locale from '../utils/locale';

export default {
  data: function(){
    return {
      toggleLanguage: 0,
    }
  },
  methods: {
    setLanguage(language) {
      this.toggleLanguage = language === "french" ? 1 : 0;
      Locale.setLanguage(language);
    },
    getLanguage() {
      return Locale.getLanguage();
    },
  },
  mounted: function(){
    this.toggleLanguage = this.getLanguage() === "french" ? 1 : 0;
  }
};
</script>
