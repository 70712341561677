import { sumBy } from 'lodash';
import { getLocale } from './localize';


// Tax Rates tables and basic return values

type TaxBracket = {
  max: number;
  tax: number;
}

type id = string;

const canTaxRates: TaxBracket[] = [
  { max: 12421, tax: 0 },
  { max: 49020, tax: 0.15 },
  { max: 98040, tax: 0.205 },
  { max: 151978, tax: 0.26 },
  { max: 216511, tax: 0.29 },
  { max: Infinity, tax: 0.33 },
];

const provTaxRates: Record<id, TaxBracket[]> = {
  AB: [
    { max: 19369, tax: 0 },
    { max: 131220, tax: 0.1 },
    { max: 157464, tax: 0.12 },
    { max: 209952, tax: 0.13 },
    { max: 314928, tax: 0.14 },
    { max: Infinity, tax: 0.15 },
  ],
  BC: [
    { max: 11070, tax: 0 },
    { max: 42184, tax: 0.0506 },
    { max: 84369, tax: 0.0770 },
    { max: 96866, tax: 0.105 },
    { max: 117623, tax: 0.1229 },
    { max: 159483, tax: 0.1470 },
    { max: 222420, tax: 0.1680 },
    { max: Infinity, tax: 0.2050 },
  ],
  MB: [
    { max: 9936, tax: 0 },
    { max: 33723, tax: 0.108 },
    { max: 72885, tax: 0.1275 },
    { max: Infinity, tax: 0.1740 },
  ],
  NB: [
    { max: 10564, tax: 0 },
    { max: 43835, tax: 0.0968 },
    { max: 87671, tax: 0.1482 },
    { max: 142534, tax: 0.1652 },
    { max: 162383, tax: 0.1784 },
    { max: Infinity, tax: 0.203 },
  ],
  NL: [
    { max: 9536, tax: 0 },
    { max: 38081, tax: 0.087 },
    { max: 76161, tax: 0.145 },
    { max: 135973, tax: 0.158 },
    { max: 190363, tax: 0.173 },
    { max: Infinity, tax: 0.183 },
  ],
  NT: [
    { max: 15243, tax: 0 },
    { max: 44396, tax: 0.059 },
    { max: 88796, tax: 0.086 },
    { max: 144362, tax: 0.122 },
    { max: Infinity, tax: 0.1405 },
  ],
  NS: [
    { max: 8481, tax: 0 },
    { max: 29590, tax: 0.0879 },
    { max: 59180, tax: 0.1495 },
    { max: 93000, tax: 0.1667 },
    { max: 150000, tax: 0.175 },
    { max: Infinity, tax: 0.21 },
  ],
  NU: [
    { max: 16467, tax: 0 },
    { max: 46740, tax: 0.04 },
    { max: 93480, tax: 0.07 },
    { max: 151978, tax: 0.09 },
    { max: Infinity, tax: 0.115 },
  ],
  ON: [
    { max: 10880, tax: 0 },
    { max: 45142, tax: 0.0505 },
    { max: 90287, tax: 0.0915 },
    { max: 150000, tax: 0.1116 },
    { max: 220000, tax: 0.1216 },
    { max: Infinity, tax: 0.1316 },
  ],
  PE: [
    { max: 10500, tax: 0 },
    { max: 31984, tax: 0.098 },
    { max: 63969, tax: 0.138 },
    { max: Infinity, tax: 0.167 },
  ],
  QC: [
    { max: 15728, tax: 0 },
    { max: 45105, tax: 0.15 },
    { max: 90200, tax: 0.2 },
    { max: 109755, tax: 0.24 },
    { max: Infinity, tax: 0.2575 },
  ],
  SK: [
    { max: 16225, tax: 0 },
    { max: 45677, tax: 0.105 },
    { max: 130506, tax: 0.125 },
    { max: Infinity, tax: 0.145 },
  ],
  YT: [
    { max: 13808, tax: 0 },
    { max: 49020, tax: 0.064 },
    { max: 98040, tax: 0.09 },
    { max: 151978, tax: 0.109 },
    { max: 500000, tax: 0.128 },
    { max: Infinity, tax: 0.15 },
  ],
};

type results = { max: number | null; min: number; rate: number; amount: number };

const getTaxTable = (grossIncome: number, taxTable: TaxBracket[]): Array<results> => {
  let min = 0;
  let taxAmount: number;
  const results: Array<results> = [];
  taxTable.forEach((bracket) => {
    if (grossIncome < 0) { return; }
    if (grossIncome <= bracket.max) {
      taxAmount = Math.max(grossIncome - min, 0) * bracket.tax;
    } else {
      taxAmount = (bracket.max - min) * bracket.tax;
    }

    const result = {
      max: bracket.max === Infinity ? grossIncome : bracket.max,
      min: min,
      rate: bracket.tax,
      amount: taxAmount
    };
    min = bracket.max;

    if (grossIncome >= result.min) { results.push(result); }
  });
  return results;
};

const getTaxRates = (grossIncome = 0, taxTable: TaxBracket[]) => {
  let rate = 0
  for (const bracket of taxTable) {
    if (grossIncome <= bracket.max) {
      rate = bracket.tax;
      break;
    }
  }
  return rate;
};

// Vuetify input rules

export const incomeRules = [
  (value: number) => !isNaN(value) || 'Not a valid number',
  (value: number) => value > 20000 || 'Cannot be smaller than $20000',
];

export const depositRules = [
  (value: number) => !isNaN(value) || 'Not a valid number',
  (value: number) => value > 100 || 'Cannot be smaller than $100',
];

export const savingsRules = [
  (value: number) => !isNaN(value) || 'Not a valid number',
];

export const percentRules = [
  (value: number) => !isNaN(value) || 'Not a valid number',
  (value: number) => (value >= 0 && value <= 100) || 'Must be between 0 and 100',
];

export const yearRules = [
  (value: number) => !isNaN(value) || 'Not a valid number',
  (value: number) => (value >= 0 && value <= 100) || 'Must be between 0 and 100',
];

// Common selector arrays

export const provinceList: Array<{ id: string; name: string }> = [
  {id:'AB', name: 'Alberta'},
  {id:'BC', name: 'British Columbia'},
  {id:'MB', name: 'Manitoba'},
  {id:'NB', name: 'New Brunswick'},
  {id:'NL', name: 'Newfoundland and Labrador'},
  {id:'NS', name: 'Nova Scotia'},
  {id:'NT', name: 'Northwest Territories'},
  {id:'NU', name: 'Nunavut'},
  {id:'ON', name: 'Ontario'},
  {id:'PE', name: 'Prince Edward Island'},
  {id:'QC', name: 'Quebec'},
  {id:'SK', name: 'Saskatchewan'},
  {id:'YT', name: 'Yukon'},
];

export const provinceListFr: Array<{ id: string; name: string}> = [
  {id:'AB', name: 'Alberta'},
  {id:'BC', name: 'Colombie-Britannique'},
  {id:'MB', name: 'Manitoba'},
  {id:'NB', name: 'Nouveau-Brunswick'},
  {id:'NL', name: 'Terre-Neuve-et-Labrador'},
  {id:'NS', name: 'Nouvelle-Écosse'},
  {id:'NT', name: 'Territoires du Nord-Ouest'},
  {id:'NU', name: 'Nunavut'},
  {id:'ON', name: 'Ontario'},
  {id:'PE', name: 'Ile du Prince-Édouard'},
  {id:'QC', name: 'Québec'},
  {id:'SK', name: 'Saskatchewan'},
  {id:'YT', name: 'Yukon'},
];

export const frequencyList: Array<{ id: number; name: string}> = [
  {id: 52, name: 'Weekly'},
  {id: 24, name: 'Twice a month'},
  {id: 12, name: 'Monthly'},
  {id: 4, name: 'Every three months'},
  {id: 2, name: 'Twice a year'},
  {id: 1, name: 'Yearly'},
];

export const frequencyListFr: Array<{ id: number; name: string}> = [
  {id: 52, name: 'Hebdomadaire'},
  {id: 24, name: 'Deux fois par mois'},
  {id: 12, name: 'Mensuelles'},
  {id: 4, name: 'Chaque trois mois'},
  {id: 2, name: 'Deux fois par année'},
  {id: 1, name: 'Annuelles'},
];

// locally used calculations

const interestFactor = (rate: number, periods: number): number => {
  return Math.pow(1 + rate, periods);
}

const getPresentValue = (futureValue: number, rate: number, periods: number): number  => {
  return futureValue / interestFactor(rate, periods);
}

// calculations used externally

export const getPeriodicRate = (rate: number, frequency: number): number => {
  return interestFactor((rate), (1 / frequency)) - 1;
}

export function showCurrencyBuilder(getLocale: () => string) {
  return (amount: number, options = {}) => {
    const settings = {
      decimals: 2,
      zero: null,
    };

    Object.assign(settings, options);

    if (amount == null || (+amount === 0 && settings.zero)) return settings.zero;
    const amountInteger = +amount;
    const currencyStyle = { style: 'currency', currency: 'CAD', minimumFractionDigits: settings.decimals };
    const currency = amountInteger.toLocaleString(getLocale(), currencyStyle);
    return currency;
  };
}
export const showCurrency = showCurrencyBuilder(getLocale);

export function showPercentBuilder(getLocale: () => string) {
  return (value = 0, round = false): string => {
    const digits = round ? 0 : 2
    const percentStyle = { style: "percent", maximumFractionDigits: digits, minimumFractionDigits: digits }
    return value.toLocaleString(getLocale(), percentStyle);
  };
}
export const showPercent = showPercentBuilder(getLocale)

export const getMarginalTaxRate = (grossIncome = 0, province: string): number => {
  if (!province) {
    throw Error('no province');
  }
  const canTaxRate = getTaxRates(grossIncome, canTaxRates);
  const provTaxRate = getTaxRates(grossIncome, provTaxRates[province]);
  return canTaxRate + provTaxRate;
}

export const getCanadaTaxTable = (grossIncome = 0): Array<results>  => {
  return getTaxTable(grossIncome, canTaxRates);
};

export const getProvincialTaxTable = (grossIncome = 0, province: string): Array<results>  => {
  if (!province) {
    throw Error('no province');
  } else
    return getTaxTable(grossIncome, provTaxRates[province]);
};

export const getCanTaxes = (grossIncome = 0): number => {
  return sumBy(getCanadaTaxTable(grossIncome), 'amount');
}
export const getProvTaxes = (grossIncome = 0, province: string): number => {
  return sumBy(getProvincialTaxTable(grossIncome, province), 'amount');
}

export const getTotalTaxes = (grossIncome = 0, province: string): number => {
  const canTaxAmount = sumBy(getCanadaTaxTable(grossIncome), 'amount');
  const provTaxAmount = sumBy(getProvincialTaxTable(grossIncome, province), 'amount');
  return canTaxAmount + provTaxAmount;
}

export const getCppEi = (income = 0): number => {
  // 2021 EI rate is 1.62% for each dollar of income between $0 and $53,100 to a maximum of $860.22
  let ei;
  const eiRate = 0.0158;

  if (income > 53100) {
    ei = 889.54; // max contribution
  } else {
    ei = income * eiRate;
  }

  // 2021 CPP rate is 5.1% for each dollar of income between $3,500 and $57,400. maximum annual contribution of $2,748.90
  const cppRate = 0.0545;
  let cpp = 0;

  if (income >= 3500 && income <= 58100) {
    // need to subtract 3500 from income because the rate is applied on each dollar between the two amounts
    cpp = (income - 3500) * cppRate;
  } else if (income >= 58101) {
    cpp = 3166.45; // max contribution
  }

  return cpp + ei;
}

export const getTaxDeduction = (grossIncome = 0, deduction = 0, province: string): number => {
  const before = getTotalTaxes(grossIncome, province);
  const after = getTotalTaxes(grossIncome - deduction, province);
  return before - after;
};

export const realRateOfReturn = (nominalRate: number, inflationRate: number): number =>  {
  return (1 + nominalRate)/(1 + inflationRate) - 1;
}

// export const futureValueByContributionFrequency = (contribution: number, frequency: number, rate: number, years: number, presentValue: number): number  => {
//   let runningTotal = presentValue;
//   const periodicRate = getPeriodicRate(rate, frequency);

//   for (let i = 0; i < frequency * years; i++) {
//     runningTotal += (runningTotal * periodicRate) + contribution;
//   }
//   return runningTotal;
// };

export const annuityPaymentFromPresentValue = (value: number, rate: number, period: number, futureValue = 0): number  => {
  const monthlyPeriod = period * 12;
  const monthlyRate = rate / 12;
  if (monthlyRate === 0) return (value - futureValue) / monthlyPeriod;
  return  monthlyRate * (value - getPresentValue(futureValue, monthlyRate, monthlyPeriod)) /
    (1 - interestFactor(monthlyRate, - monthlyPeriod));
}

export default {
  provinceList,
  provinceListFr,
  frequencyList,
  frequencyListFr,
  canTaxRates,
  provTaxRates,
  incomeRules,
  depositRules,
  savingsRules,
  percentRules,
  yearRules,
  showPercent,
  showCurrency,
  getPeriodicRate,
  getCanadaTaxTable,
  getProvincialTaxTable,
  getTotalTaxes,
  getMarginalTaxRate,
  getCppEi,
  getTaxDeduction,
  realRateOfReturn,
  annuityPaymentFromPresentValue,
}
