

























































































































import { defineComponent, reactive, onMounted, watch } from "@vue/composition-api";
import {
  provinceList,
  provinceListFr,
  getCanadaTaxTable,
  getProvincialTaxTable,
  getCanTaxes,
  getProvTaxes,
  getTotalTaxes,
  getTaxDeduction,
  getCppEi,
  showPercent,
  showCurrency,
  depositRules,
} from '../../lib/calc_lib';
import { $t, eventBus } from '../../main'
import Locale from '../../utils/locale';

export default defineComponent({
  name: 'taxCalc',

  setup () {
    const [provList, provListFr] = [provinceList, provinceListFr];

    const state = reactive({
      language: Locale.getLanguage(),
      province: 'BC',
      income: 60000,
      deductions: 6000,
      taxes: 0,
    });

    const results = reactive({
      taxes: 0,
      cppEi: 0,
      afterTaxIncome: 0,
      canTaxes: {},
      totalCanTax: 0,
      provTaxes: {},
      totalProvTax: 0,
      totalTax: 0,
      averageTaxRate: 0,
      taxSaved: 0,
    });

    const getGross = () => {
      return state.income - state.deductions;
    };

    const calcTaxes = () => {
      results.taxes = getTotalTaxes(getGross(), state.province);
      results.cppEi = getCppEi(state.income);
      results.afterTaxIncome = state.income - (results.taxes + results.cppEi);
      results.canTaxes = getCanadaTaxTable(getGross());
      results.provTaxes = getProvincialTaxTable(getGross(), state.province);
      results.totalCanTax = getCanTaxes(getGross());
      results.totalProvTax = getProvTaxes(getGross(), state.province);

      results.totalTax = results.totalProvTax + results.totalCanTax;
      results.taxSaved = getTaxDeduction(state.income, state.deductions, state.province);

      results.averageTaxRate = results.totalTax / (state.income - state.deductions);
    }

    onMounted(() => {
      calcTaxes();
      eventBus.$on('language-change', (language: string) => { state.language = language });
    });

    watch(state, () => {
      calcTaxes();
    });

    return {
      state,
      results,
      depositRules,
      getGross,
      provList,
      provListFr,
      showPercent,
      showCurrency,
      calcTaxes,
      $t,
    };
  },
});
