<template>
  <v-app>
    <nav-bar/>

    <v-main>
      <v-container>
        <h1 v-t>Financial Tools</h1>
        <v-tabs v-model="activeTab" color="primary" mobile-breakpoint="md" :key="language">
          <v-tab v-for="tab in tabList" :key="tab.id" @change="setRoute(tab)">
            <span>{{ tab.name }}</span>
          </v-tab>
          <v-tab @click="moreTools()">
            <span>{{ moreLink }}</span>
          </v-tab>
        </v-tabs>
      </v-container>
      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="tab in tabList" :key="tab.id" >
          <v-container>
            <component :is="tab.comp" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-main>

    <wb-footer/>
  </v-app>
</template>

<script>
import NavBar from './components/navbar.vue';
import WbFooter from './components/footer.vue';
import HomeBuyingPlan from './views/home_buying_plan/index.vue';
import TfsaRrsp from './views/tfsa_rrsp/index.vue';
import TaxCalc from './views/tax_calc/index.vue';
import SavingsPlan from './views/savings_plan/index.vue';
import { VApp, VTabs, VTab } from 'vuetify/lib';
import { $t, eventBus } from './main';
import Locale from './utils/locale.ts';

export default {
  name: 'App',

  components: {
    NavBar,
    WbFooter,
    VApp, VTabs, VTab,
    HomeBuyingPlan,
    TfsaRrsp,
    TaxCalc,
    SavingsPlan
  },
  data() {
    return {
      activeTab: 0,
      tabList: [],
      language: Locale.getLanguage(),
      moreLink: '',
    };
  },
  watch: {
    language: {
      handler: function () {
        this.tabList = [
          {
            name: $t('Home Buying Plan'),
            id: 'home-buying',
            comp: 'HomeBuyingPlan'
          },
          {
            name: $t('TFSA vs. RRSP'),
            id: 'tfsa-rrsp',
            comp: 'TfsaRrsp'
          },
          {
            name: $t('Canadian Tax Calculator'),
            id: 'tax-calc',
            comp: 'TaxCalc'
          },
          {
            name: $t('Savings Plan'),
            id: 'savings-plan',
            comp: 'SavingsPlan'
          },
        ];
        this.moreLink = $t('More Calculators and Tools')
      },
      immediate: true,
    },
  },
  created() {
    if(this.$route.query.tab) {
      this.activeTab = this.tabList.findIndex(tab => tab.id === this.$route.query.tab);
    }
    eventBus.$on('language-change', (language) => { this.language = language });
  },
  methods: {
    setRoute(tab) {
      this.$router.replace({ query: { tab: tab.id } });
    },
    $t(string) {
      return $t(string);
    },
    moreTools() {
      if(this.language === 'french') {
        window.location = 'https://www.cifinancial.com/ci-gam/ca/fr/investor-resources/calculators-tools.html'
      }
      else {
        window.location = 'https://www.cifinancial.com/ci-gam/ca/en/investor-resources/calculators-tools.html'
      }
    }
  },
};
</script>
<style lang="scss">
  @import './style_load.scss';
  @import '~chartist/dist/chartist.css';
  // @import '~vuetify/dist/vuetify.min.css';
</style>
