<template>
  <section class="chartist my-4" :style="cssProps">
    <!-- svg gradient def for chart, allows for injectible colour variables-->
    <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
      <defs>
        <linearGradient :id="chartId + '-gradient'" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="var(--primary)" />
          <stop offset="100%" stop-color="var(--alt)" />
        </linearGradient>
      </defs>
    </svg>

    <div class="ct-chart" :id="chartId"/>
  </section>
</template>

<script>
import Chartist from 'chartist';
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { showCurrency } from '@/lib/calc_lib'
import 'chartist-plugin-axistitle';
import '@/lib/chartist-plugins/ct-point-labels';
import '@/lib/chartist-plugins/only-points';
// import '@/lib/chartist-plugins/only-x-labels';
import { $t, eventBus } from '../../src/main'

export default defineComponent({
  name: 'chart',
  props: {
    labels: Array,
    series: Array,
    chartId: String,
  },
  setup(props) {
    const state = reactive(props)

    const drawChart = (labels, series) => {
      const data = {
        // A labels array that can contain any sort of values
        labels,
        // Our series array that contains series objects or in this case series data arrays
        series: [series]
      };
      const chart = new Chartist.Line(`#${props.chartId}`, data,
        {
          chartPadding: {
            top: 30, right: 15, bottom: 20, left: 10,
          },
          showArea: true,
          showPoint: false,
          fullWidth: true,
          axisX: { showGrid: true, showLabel: true },
          axisY: { showGrid: false, showLabel: true },
          plugins: [
            Chartist.plugins.wbOnlyPoints(
              data => data.type === 'line',
              (pathElements, pathElement) => pathElement.data.valueIndex === 0 || pathElement.data.valueIndex === pathElements.length - 1,
            ),
            Chartist.plugins.wbAccountCtPointLabels({
              labelInterpolationFnc: (value) => {
                return value ? showCurrency(value) : 0
              },
            }),
            Chartist.plugins.ctAxisTitle({
              axisX: {
                axisTitle: $t('Years'),
                axisClass: 'ct-axis-title',
                offset: {
                  x: 0,
                  y: 40
                },
                textAnchor: 'middle'
              }
            }),
          ]
        }
      );
      chart.on('created', (ctx) => {
        // Draws the line between the x axis and the graph
        ctx.svg.querySelector('svg > g:nth-child(2)').elem('line', {
          x1: ctx.chartRect.x1,
          x2: ctx.chartRect.x2,
          y1: ctx.chartRect.y1,
          y2: ctx.chartRect.y1,
          class: 'x-axis-line',
          stroke: 'black',
          'stroke-width': 1,
        });
      });

      return chart;
    };
    watch(state, () => {
      drawChart(state.labels, state.series);
    })

    // Redraws the chart when the event is called - on navigation to a chart page
    eventBus.$on('route:change:chart', () => drawChart(state.labels, state.series));

    return {
      drawChart,
      $t,
    };
  },

  computed: {
    cssProps() {
      return {
        '--gradient-id': `url(#${this.chartId}-gradient)`,
      }
    }
  },
});
</script>
<style lang="scss">
  //set colour stops for gradiant based on theme colours
  .chartist {
    --primary: #{$primary};
    --alt: white;
  }

  .ct-chart {
    overflow: hidden;
    position: relative;
    height: 0;
    width: 100%;
    // 16:9 aspect ratio
    padding-top: 56.25%;
    > svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    .ct-chart-line { overflow: visible; }

    g {
      .ct-line { stroke-width: 1px; }

      .ct-series {
        .ct-background-label {
          stroke: var(--alt);
          stroke-width: 0.25rem;
        }

        text:nth-of-type(-n+3) { text-anchor: start !important; }

        text:nth-of-type(n+3) { text-anchor: end !important; }
      }

      .ct-series-a {
        .ct-area { fill: var(--gradient-id); }
        .ct-line,
        .ct-point { stroke: var(--primary); }
      }
      .x-axis-line { stroke: var(--v-neutral-darken1);}
    }

    /* Puts the right label of the X axis to the right of the graph */
    // g.ct-labels foreignObject:last-child .ct-label { justify-content: flex-end; }

    .ct-label { color: var(--v-neutral-darken1) }
    .ct-axis-title { fill: var(--v-neutral-darken4); }
  }
</style>
