<template>
  <footer class="footer bg50 px-4 py-12">
    <v-container>
      <v-row>
        <p>
          <span v-t>WealthBar Financial Services Inc. doing business as CI Direct Investing (“CI Direct Investing”) and WealthBar (“WealthBar”), is a registered Portfolio Manager in all provinces and territories in Canada.</span>&nbsp;
          <span v-t>Visualizations are for illustrative purposes and don't represent actual performance.</span>
        </p>

        <p v-t>Any performance provided is for informational purposes only and is not to be considered as investment advice. Portfolio performance is not guaranteed. The value of your investment can go down, up and change frequently. Past performance is not indicative of future returns. There may be significant differences between the investment portfolios that are not discussed here, including different investment objectives and risk factors. You should always consider, in any investment decision, your investment objectives, needs, circumstances, restrictions, tolerance for risk, financial goals and investment time frame.</p>

        <p>
          <span v-t>Although CI Direct Investing believes the obtained information provided from third-party sources to be reliable, CI Direct Investing does not guarantee the information and disclaims any liability associated with the use of these performance results. For full details of calculation please contact:</span>&nbsp;
          <a href="mailto:info@cidirectinvesting.com">info@cidirectinvesting.com</a>.
        </p>
      </v-row>

      <v-row class="px-0 d-block d-lg-flex align-end">
        <div class="pt-4">
          <span>&copy; {{ new Date().getFullYear() }} </span>
          <span class="mr-8" v-t>WealthBar Financial Services Inc. All rights reserved.</span>
        </div>

        <div class="pt-4">
          <a href="/privacy" class="mr-8" v-t>Privacy Policy</a>
        </div>

        <div class="pt-4 d-lg-flex align-center ml-auto">
          <language-toggle
            class="mr-2"
            :class="{'mb-6': $vuetify.breakpoint.mdAndDown, 'mb-0': $vuetify.breakpoint.lgAndUp}"
          />
          <v-btn v-for="link in social" :key="link.name" dark fab elevation="0" small class="mr-2" :href="link.href">
            <font-awesome-icon :icon="link.icon" size="2x"/>
            <span class="d-sr-only">{{link.name}}</span>
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import LanguageToggle from '@/components/language_toggle.vue';
import { eventBus } from '../main';

export default {
  components: { LanguageToggle },

  data() {
    return {
      social: [
        {
          name: 'Facebook',
          icon: ['fab', 'facebook-f'],
          href: 'https://www.facebook.com/CIDirectInvesting'
        },
        {
          name: 'LinkedIn',
          icon: ['fab', 'linkedin-in'],
          href: 'https://www.linkedin.com/company/CIDirectInvesting'
        },
        {
          name: 'Twitter',
          icon: ['fab', 'twitter'],
          href: 'https://twitter.com/CIDirectInvest'
        },
      ]
    };
  },
  mounted() {
    eventBus.$on('language-change', () => this.$forceUpdate());
  },
};
</script>
