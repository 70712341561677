























































































import { defineComponent, reactive, onMounted, watch, computed } from "@vue/composition-api";
import {
  frequencyList,
  frequencyListFr,
  showPercent,
  showCurrency,
  getPeriodicRate,
  savingsRules,
  percentRules,
  yearRules,
} from '../../lib/calc_lib';
import Chart from '@/components/chart.vue';
import { $t, eventBus } from '../../main'
import Locale from '../../utils/locale';

export default defineComponent({
  name: 'savingsPlan',
  components: { Chart },
  setup () {
    const [freqList, freqListFr] = [frequencyList, frequencyListFr];

    const state = reactive({
      language: Locale.getLanguage(),
      savings: 10000,
      contribution: 500,
      frequency: 12,
      years: 30,
      rateOfReturn: 6.0,
    });

    const results = reactive({
      futureValue: 0,
      totalContribution: 0,
      savingsGrowth: 0,
      graphData: {},
    });

    const isFormValid = computed(() => {
      return yearRules.every(rule => rule(state.years) === true)
        && percentRules.every(rule => rule(state.rateOfReturn) === true)
    });

    const calcSavingsPlan = () => {
      const ratePecentage = state.rateOfReturn / 100
      let runningTotal = state.savings;
      const graphSeries = [state.savings];
      const graphLabel: (number|null)[] = [0];
      const periodicRate = getPeriodicRate(ratePecentage, state.frequency);

      for (let i = 1; i <= state.frequency * state.years; i++) {
        runningTotal += (runningTotal * periodicRate) + state.contribution;
        graphSeries[i] = runningTotal;
        if (i == state.frequency * state.years){
          graphLabel[i] = i/ state.frequency;
        } else if (state.years > 49) {
          graphLabel[i] = (i / state.frequency) % 10 ? null : i/ state.frequency;
        } else if (state.years > 11) {
          graphLabel[i] = (i / state.frequency) % 5 ? null : i/ state.frequency;
        } else {
          graphLabel[i] = ( i % state.frequency ) ? null : i / state.frequency;
        }
      }

      results.totalContribution = state.contribution * (state.frequency * state.years);
      results.futureValue = runningTotal;
      results.savingsGrowth = results.futureValue - results.totalContribution - state.savings;

      if (isFormValid.value === true) {
        results.graphData = {
          label: graphLabel,
          series: graphSeries
        };
      }
      
    };

    onMounted(() => {
      calcSavingsPlan();
      eventBus.$on('language-change', (language: string) => { state.language = language });
    });

    watch(state, () => {
      calcSavingsPlan();
    });

    return {
      state,
      results,
      freqList,
      freqListFr,
      showPercent,
      showCurrency,
      calcSavingsPlan,
      savingsRules,
      percentRules,
      yearRules,
      isFormValid,
      $t,
    };
  },
});
