import Chartist from 'chartist';

/*
  This plugins is a rewrite of ct point labels.
  It was necessary to create this new version to place labels at the right spot.
  I tried to stick as closely to the original as I could in case we want to update it.
*/

/* eslint-disable */
(() /* istanbul ignore next: chartist code */ =>  {
  var defaultOptions = {
    labelClass: 'ct-label',
    labelOffset: {
      x: 0,
      y: -10
    },
    textAnchor: 'middle',
    align: 'center',
    labelInterpolationFnc: Chartist.noop
  };

  var labelPositionCalculation = {
    point: function(data) {
      return {
        // This is the part that has been changed.
        x: data.index === 0 ? data.x + 6 : data.x - 6,
        y: data.y
      };
    },
    bar: {
      left: function(data) {
        return {
          x: data.x1,
          y: data.y1
        };
      },
      center: function(data) {
        return {
          x: data.x1 + (data.x2 - data.x1) / 2,
          y: data.y1
        };
      },
      right: function(data) {
        return {
          x: data.x2,
          y: data.y1
        };
      }
    }
  };

  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.wbAccountCtPointLabels = function(options) {

    options = Chartist.extend({}, defaultOptions, options);

    function addLabel(position, data) {
      // if x and y exist concat them otherwise output only the existing value
      var value = data.value.x !== undefined && data.value.y ?
        (data.value.x + ', ' + data.value.y) :
        data.value.y || data.value.x;

      data.group.elem('text', {
        x: position.x + options.labelOffset.x,
        y: position.y + options.labelOffset.y,
        class: 'ct-background-label',
        style: 'text-anchor: ' + options.textAnchor
      }, options.labelClass).text(options.labelInterpolationFnc(value));
      data.group.elem('text', {
        x: position.x + options.labelOffset.x,
        y: position.y + options.labelOffset.y,
        style: 'text-anchor: ' + options.textAnchor
      }, options.labelClass).text(options.labelInterpolationFnc(value));
    }

    return function ctPointLabels(chart) {
      if (chart instanceof Chartist.Line || chart instanceof Chartist.Bar) {
        chart.on('draw', function(data) {
          var positonCalculator = labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align] || labelPositionCalculation[data.type];
          if (positonCalculator) {
            addLabel(positonCalculator(data), data);
          }
        });
      }
    };
  };
})();
/* eslint-enable */
