














































































































































































































import { defineComponent, reactive, watch, onMounted, computed } from "@vue/composition-api";
import {
  realRateOfReturn,
  annuityPaymentFromPresentValue,
  showPercent,
  showCurrency,
  incomeRules,
  savingsRules,
  percentRules,
  yearRules
} from '../../lib/calc_lib'
import Chart from '@/components/chart.vue';
import { $t, eventBus } from '../../main'

type Results = {
  planData: { [key: string]: number };
  graphData: {};
}

export default defineComponent({
  components: { Chart },

  setup () {
    const state = reactive({
      language: '',
      setfalse: false,
      years: 5,
      salePrice: 550000,
      income: 100000,
      savings: 2000,
      downpayment: 20,
      mortgageRate: 3.5,
      yearlyUpkeep: 2,
      closingCosts: 3,
      interestRate: 4,
      inflationRate: 2,
      mortgagePeriod: 25,
      rrspSavings: 0,
      tfsaSavings: 0,
      otherSavings: 0,
      moreCollapse: false,
      panel: false,
    });

    const results: Results = reactive({
      planData: {},
      graphData: {},
    });

    const isFormValid = computed(() => {
      return percentRules.every(rule => rule(state.downpayment) === true)
        && percentRules.every(rule => rule(state.mortgageRate) === true)
        && percentRules.every(rule => rule(state.yearlyUpkeep) === true)
        && percentRules.every(rule => rule(state.closingCosts) === true)
        && percentRules.every(rule => rule(state.interestRate) === true)
        && percentRules.every(rule => rule(state.inflationRate) === true)
        && yearRules.every(rule => rule(state.mortgagePeriod) === true)
        && savingsRules.every(rule => rule(state.rrspSavings) === true)
        && savingsRules.every(rule => rule(state.tfsaSavings) === true)
        && savingsRules.every(rule => rule(state.otherSavings) === true)
    });

    const asPercent = (value: number) => {
      return value / 100;
    }

    const calcBuyingPlan = () => {
      let month = 0;
      let combinedSavings = state.otherSavings + state.tfsaSavings;
      const plan = [];
      const realRate = realRateOfReturn(asPercent(state.interestRate), asPercent(state.inflationRate));
      if (state.savings > 1) {
        while (state.salePrice>=50000 && combinedSavings <= (asPercent(state.downpayment) + asPercent(state.closingCosts)) * state.salePrice) {
          month++;
          combinedSavings = state.savings + combinedSavings * (1 + (realRate/12));

          plan.push({
            month: month - 1,
            savings: combinedSavings,
          })
        }
      }
      const downpayment = state.salePrice * asPercent(state.downpayment);
      const closingCosts = state.salePrice * asPercent(state.closingCosts);
      const monthlyUpkeep = (state.salePrice * asPercent(state.yearlyUpkeep)) / 12
      const monthlyMortgage = annuityPaymentFromPresentValue (
        state.salePrice - downpayment,
        asPercent(state.mortgageRate),
        state.mortgagePeriod
      );
      const monthlyTotal = monthlyMortgage + monthlyUpkeep;
      const incomePercentage = (monthlyTotal * 12) / state.income;
      const unspentSavings = combinedSavings - (downpayment + closingCosts);

      results.planData = {
        totalSavings: combinedSavings,
        years: Math.floor(month / 12),
        months: month % 12,
        downpayment,
        closingCosts,
        unspentSavings,
        monthlyMortgage,
        monthlyUpkeep,
        monthlyTotal,
        incomePercentage,
      }

      //graphing data
      const graphSeries: (number|null)[] = plan.map(d => d.savings);
      const graphLabel: (number|null)[] = [];
      if (plan.length > 1200) {
        plan.forEach(e => { ( e.month % 120 ) ? graphLabel.push(null) :graphLabel.push(e.month / 12)});
      } else if (plan.length > 240) {
        plan.forEach(e => { ( e.month % 60 ) ? graphLabel.push(null) :graphLabel.push(e.month / 12)});
      } else {
        plan.forEach(e => { ( e.month % 12 ) ? graphLabel.push(null) :graphLabel.push(e.month / 12)});
      }

      if (isFormValid.value === true) {
        results.graphData = {
          label: graphLabel,
          series: graphSeries
        };
      }
    }

    onMounted(() => {
      calcBuyingPlan();
      eventBus.$on('language-change', (language: string) => { state.language = language });
    });

    watch(state, () => {
      calcBuyingPlan();
    });

    return {
      state,
      results,
      showPercent,
      showCurrency,
      calcBuyingPlan,
      asPercent,
      incomeRules,
      savingsRules,
      percentRules,
      yearRules,
      isFormValid,
      $t,
    };
  }
});
