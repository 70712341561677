import Vue from 'vue';
import Router from 'vue-router';
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue';
import vuetify from '@/utils/vuetify'

// Import everything needed for translation
import { translate, registerLanguages, i18nMixin, changeLanguage } from '@wealthbar/i18n';
import locale from './utils/locale';
import processLabels from './utils/process-labels';

export const language = locale.getLanguage().slice(0, 2) === 'fr' ? 'fr-CA' : 'en';
export const eventBus = new Vue();

const routes = [
  {
    path: '/',
    name: 'App',
    component: App,
    meta: {
      title: language === 'fr-CA' ? 'Investissement direct CI - Outils financiers' : 'CI Direct Investing - Financial Tools',
    },
  }
];

const router = new Router({
  mode: 'history',
  routes
});

// let's set a title for our page as simply as possible since we have only one route.
// for a more robust option, see https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

// To reset the chart when navigating to a chart page. Ensures chart is redrawn on navigation
router.afterEach((to) => {
  if(['home-buying','savings-plan'].includes(to.query.tab as string)){ 
    eventBus.$emit('route:change:chart');
  }
});

Vue.use(Router);
Vue.use(VueCompositionAPI);

Vue.directive('t', 
  // @ts-ignore: TODO: export this properly from the node module
  i18nMixin.directives.translate
);

registerLanguages({
  'fr-CA': async () => {
    // @ts-ignore: TODO: do we need to import a type declaration for this?
    const lang = await import('./locale/fr_CA/app.po');
    return processLabels(lang);
  },
});

changeLanguage(language).then(() => {
  return new Vue({
    router,
    vuetify,
    el: '#app',
    render(h) { return h(App); },
  });
});

export const $t = translate;